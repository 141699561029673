import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  standalone: true,
  name: 'byPassSecurityBlob'
})
export class ByPassSecurityBlobPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(value: Blob | undefined | null): SafeResourceUrl {
    if (!value) {
      return '';
    }
    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(value));
  }
}
