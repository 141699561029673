import { PrimeIcons } from 'primeng/api';

export const SectionIcons = [
  PrimeIcons.CIRCLE_FILL,
  PrimeIcons.STAR_FILL,
  PrimeIcons.HEART_FILL,
  PrimeIcons.THUMBS_UP,
  PrimeIcons.THUMBS_DOWN,
  PrimeIcons.PLUS,
  PrimeIcons.TIMES,
  PrimeIcons.SLIDERS_V,
  PrimeIcons.CHECK,
  PrimeIcons.SORT_UP,
  PrimeIcons.INFO_CIRCLE,
  PrimeIcons.EXCLAMATION_TRIANGLE,
  PrimeIcons.ENVELOPE,
  PrimeIcons.CLOCK,
  PrimeIcons.CALENDAR,
  PrimeIcons.FOLDER,
  PrimeIcons.FILE,
  PrimeIcons.HISTORY,
  PrimeIcons.USERS,
  PrimeIcons.USER,
  PrimeIcons.USER_EDIT,
  PrimeIcons.REPLAY,
  PrimeIcons.MINUS_CIRCLE,
  PrimeIcons.MOBILE,
  PrimeIcons.CHECK_SQUARE,
  PrimeIcons.BELL,
  PrimeIcons.BOOK
] as const;
