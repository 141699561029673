import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const GRAPH_ENDPOINT_PIC = 'https://graph.microsoft.com/v1.0/me/photo/$value';

export interface Profile {
  displayName: string;
  givenName: string;
  id: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  surname: string;
  userPrincipalName: string;
}

@Injectable({ providedIn: 'root' })
export class AzureADApiService {
  isUserLoggedIn: Subject<boolean> = new Subject<boolean>();
  constructor(private httpClient: HttpClient) {}

  getUserProfile(): Observable<Profile> {
    return this.httpClient.get<Profile>(GRAPH_ENDPOINT);
  }

  getUserProfilePic(): Observable<Blob> {
    return this.httpClient.get(GRAPH_ENDPOINT_PIC, {
      responseType: 'blob'
    });
  }
}
