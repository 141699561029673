<ng-container *ngIf="{
                      profile: (profile$ | async),
                      profilePicBlob: (profilePicBlob$ | async)
                    } as vm">
  <p-toolbar styleClass="surface-card shadow-4">
    <div class="p-toolbar-group-left">
      <div class="mr-2">
        <div class="block lg:hidden">
          <p-button #sidebarBtn
                    pRipple
                    icon="pi pi-bars"
                    styleClass="p-button-text p-button-plain"
                    (onClick)="toggleSidebar()"></p-button>
        </div>
      </div>
      <p-image src="../../assets/LogoPenstock.svg"
               alt="Penstock Consulting"
               width="100%"></p-image>
    </div>
    <div class="p-toolbar-group-right">
      <p-button pRipple
                styleClass="md:hidden"
                [icon]="PrimeIcons.LIST"
                (onClick)="menu.toggle($event)"></p-button>
      <p-button pRipple
                styleClass="hidden md:inline-block"
                [icon]="PrimeIcons.LIST"
                [label]="vm.profile?.displayName || 'user'"
                (onClick)="menu.toggle($event)"></p-button>
      <p-menu #menu
              [popup]="true"
              [model]="userMenuItems"></p-menu>
      <div class="ml-1 overflow-hidden">
        <p-image class="inline-block"
                 styleClass="overflow-hidden"
                 [src]="vm.profilePicBlob | byPassSecurityBlob"
                 alt=""
                 height="35"></p-image>
      </div>
    </div>
  </p-toolbar>
</ng-container>

<router-outlet #outlet="outlet"></router-outlet>
