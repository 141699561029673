import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { PrimeNGConfig } from 'primeng/api';
import { filter, Subject, takeUntil } from 'rxjs';
import { AdminPortalPath } from './app.enum.path.routes';
import { AppState } from './store/app.state';
import { selectIsFetchingData } from './store/selectors/fetching-data.selectors';

@Component({
  selector: 'penstock-root',
  templateUrl: './app.component.html',
  styles: [':host { display: block; }']
})
export class AppComponent implements OnInit, OnDestroy {
  isFetchingData$ = this.store.select(selectIsFetchingData);
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private broadcastService: MsalBroadcastService,
    private store: Store<AppState>,
    private primengConfig: PrimeNGConfig,
    private msalService: MsalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => this.redirectToPortalIfLoginSuccess());
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  redirectToPortalIfLoginSuccess() {
    const loginSuccessful = this.msalService.instance.getAllAccounts().length > 0;
    if (loginSuccessful) {
      this.router.navigate(['/', AdminPortalPath.base]);
    }
  }
}
