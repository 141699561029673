import { ImageModule } from 'primeng/image';
import { ToolbarModule } from 'primeng/toolbar';
import { Component, Inject } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

@Component({
  standalone: true,
  imports: [CardModule, ButtonModule, FontAwesomeModule, ToolbarModule, ImageModule],
  selector: 'penstock-login',
  templateUrl: './login.component.html',
  styles: [':host { display: block; }']
})
export class LoginComponent {
  faMicrosoft = faMicrosoft;

  constructor(private msalService: MsalService, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {}

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }
}
