import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Action, StoreModule } from '@ngrx/store';
import { ApiErrorHandlerInterceptor } from '@penstock/data-access/api-error-handler';
import { LoadingComponent } from '@penstock/feature/loading';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AppComponent } from './app.component';
import { AdminPortalPath } from './app.enum.path.routes';
import { MSAL_CONFIG } from './auth-config';
import { LoginComponent } from './routes/login/login.component';
import { PortalBaseComponent } from './routes/portal-base/portal-base.component';
import { LoadingInterceptor } from './services/interceptors/loading.interceptor';
import { AppState } from './store/app.state';
import { fetchingDataReducer } from './store/reducers';
import { userReducer } from './store/reducers/user.reducer';
import { NgxWebstorageModule } from 'ngx-webstorage';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: AdminPortalPath.base,
    component: PortalBaseComponent,
    loadChildren: () => import('./routes/portal-base/portal-base.routes').then(r => r.PORTAL_BASE_ROUTES),
    canActivate: [MsalGuard]
  },
  { path: '**', redirectTo: '' }
];

type Reducer = (state: unknown | undefined, action: Action) => unknown;
const reducers: Record<keyof AppState, Reducer> = {
  fetchingData: fetchingDataReducer as Reducer,
  user: userReducer as Reducer
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'top'
    }),
    NgxWebstorageModule.forRoot(),
    StoreModule.forRoot(reducers),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: '258d4d39-8613-42c5-9704-5e8afda5beee',
          redirectUri: MSAL_CONFIG.redirectUri,
          authority: 'https://login.microsoftonline.com/9b6c5e05-8ece-49db-914d-5522a8f777af'
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false
        }
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['user.read']
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.Read']],
          [MSAL_CONFIG.protectedResourceApi, ['api://ec8f10f1-a99b-4212-b1b5-e5402c7db1ea/api.scope']]
        ])
      }
    ),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    LoadingComponent,
    ToastModule
  ],
  providers: [
    { provide: Window, useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorHandlerInterceptor, multi: true },
    MsalGuard,
    MessageService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
