import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { UserRole } from '@penstock/models';
import { AppState } from '../app.state';

export const updateUserEmail = createAction('[USER] Update Email', props<{ email: string }>());
export const updateUserName = createAction('[USER] Update Name', props<{ firstName: string; lastName: string }>());
export const updateUserRoles = createAction('[USER] Update Roles', props<{ roles: ReadonlyArray<UserRole> }>());
export const clearUser = createAction('[USER] Clear');

@Injectable({ providedIn: 'root' })
export class UserActionsService {
  constructor(private store: Store<AppState>) {}

  updateUserEmail = (email: string): void => this.store.dispatch(updateUserEmail({ email }));

  updateUserName = (firstName: string, lastName: string): void => this.store.dispatch(updateUserName({ firstName, lastName }));

  updateUserRoles = (roles: UserRole[]): void => this.store.dispatch(updateUserRoles({ roles }));

  clearUser = (): void => this.store.dispatch(clearUser());
}
