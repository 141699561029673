import { CommonModule } from '@angular/common';
import { Component, ViewChild, ElementRef, HostListener } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ByPassSecurityBlobPipe } from '@penstock/feature/by-pass-security-blob';
import { ThemeService } from '@penstock/feature/theme';
import { EnvConfig } from '@penstock/models';
import { LocalStorageService } from 'ngx-webstorage';
import { PrimeIcons } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { MenuModule } from 'primeng/menu';
import { ToolbarModule } from 'primeng/toolbar';
import { AzureADApiService, Profile } from '../../services/api/azure-ad.api.service';
import { SidebarService } from '../../services/sidebar.service';
import { OnInit } from '@angular/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    FontAwesomeModule,
    ToolbarModule,
    ImageModule,
    FontAwesomeModule,
    ByPassSecurityBlobPipe,
    MenuModule
  ],
  templateUrl: './portal-base.component.html',
  styleUrls: ['./portal-base.component.scss'],
  styles: [':host { display: block; }']
})
export class PortalBaseComponent implements OnInit {
  @ViewChild('sidebarBtn', { static: false, read: ElementRef })
  private sidebarBtn!: ElementRef;

  PrimeIcons = PrimeIcons;
  profile?: Profile;
  profilePicBlob?: Blob;
  userMenuItems = [
    {
      label: 'Dark Theme',
      icon: PrimeIcons.MOON,
      command: () => this.changeTheme('primeng-bootstrap4-dark-blue.theme')
    },
    {
      label: 'Light Theme',
      icon: PrimeIcons.SUN,
      command: () => this.changeTheme('primeng-bootstrap4-light-blue.theme')
    },
    {
      label: 'Logout',
      icon: PrimeIcons.SIGN_OUT,
      command: () => this.logout()
    }
  ];

  profile$ = this.azureADService.getUserProfile();
  profilePicBlob$ = this.azureADService.getUserProfilePic();

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  isSidebarBtnVisible: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    // sidebar visible
    if (this.sidebarBtn) {
      if (this.sidebarBtn.nativeElement.offsetParent === null && window.innerWidth > 768) {
        this.sidebarService.setSidebarVisibility(true);
      }
    }
  }

  constructor(
    private msalService: MsalService,
    private azureADService: AzureADApiService,
    private themeService: ThemeService,
    private envConfig: EnvConfig,
    private sidebarService: SidebarService,
    private localStorageService: LocalStorageService
  ) {}

  private logout(): void {
    this.msalService.logoutRedirect({ postLogoutRedirectUri: this.envConfig.spaUrl });
  }

  ngOnInit(): void {
    this.checkLocalStorageForTheme();
  }
  checkLocalStorageForTheme(): void {
    const existingTheme = this.localStorageService.retrieve('theme');
    if (existingTheme) {
      this.themeService.switchTheme(existingTheme);
    }
  }
  changeTheme(theme: string): void {
    const newTheme = theme;
    if (newTheme) {
      this.themeService.switchTheme(newTheme);
      this.localStorageService.store('theme', newTheme);
    }
  }
  toggleSidebar(): void {
    this.sidebarService.toggleSidebar();
  }
}
