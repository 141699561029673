import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidebarVisible = false;
  isVisible = new BehaviorSubject<boolean>(this.sidebarVisible);

  getSidebarVisibility() {
    return this.sidebarVisible;
  }

  setSidebarVisibility(visible: boolean) {
    this.sidebarVisible = visible;
    this.isVisible.next(visible);
  }

  toggleSidebar() {
    this.isVisible.next(!this.isVisible.value);
  }
}
