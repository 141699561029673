import { createReducer, on, Action } from '@ngrx/store';
import { userActions } from '../actions';
import { UserState } from '../models/user-model';

const initialState: UserState = {
  email: '',
  firstName: '',
  lastName: '',
  roles: []
} as const;

const _userReducer = createReducer(
  initialState,

  on(userActions.updateUserEmail, (state, { email }) => {
    return { ...state, email };
  }),

  on(userActions.updateUserName, (state, { firstName, lastName }) => {
    return { ...state, firstName, lastName };
  }),

  on(userActions.updateUserRoles, (state, { roles }) => {
    return { ...state, roles: [...roles] };
  }),

  on(userActions.clearUser, state => {
    return { ...state, ...initialState };
  })
);

export function userReducer(state: UserState | undefined, action: Action): UserState {
  return _userReducer(state, action);
}
