<p-toolbar styleClass="bg-white shadow-4">
  <div class="p-toolbar-group-left">
    <p-image src="../../assets/LogoPenstock.svg"
             alt="Image"
             width="250"></p-image>
  </div>
  <div class="p-toolbar-group-right"></div>
</p-toolbar>

<div class="grid mt-5">
  <div class="col-12 md:col-offset-2 md:col-8 lg:col-offset-3 lg:col-6 text-center">
    <p-card styleClass="shadow-4">
      <ng-template pTemplate="header">
        <div class="px-4 pt-1 pb-0"></div>
      </ng-template>
      <ng-template pTemplate="title">Login</ng-template>
      <ng-template pTemplate="content">
        <p-button styleClass="p-button-lg p-button-raised"
                  (onClick)="loginRedirect()">
          <fa-icon class="color-primary"
                   [icon]="faMicrosoft"></fa-icon>
          <span class="ml-2 font-bold">Penstock Account</span>
        </p-button>
      </ng-template>
    </p-card>
  </div>
</div>
